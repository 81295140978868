<template>
  <div>
    <v-container class="fill-height">
      <v-row class="justify-center align-center">
        <v-col cols="12" sm="4" align="center">
          <h3 class="mb-3">{{ text }}</h3>
          <v-progress-circular
            class="justify-center"
            :size="60"
            color="primary"
            indeterminate
          ></v-progress-circular
        ></v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: ["text"],
};
</script>

<style scoped></style>
